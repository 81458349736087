// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  database: {
    firebaseConfig: {
      apiKey: 'AIzaSyCkD2vo5Mnj4OdWZ3pCLG6rs5XQ_lucUxE',
      authDomain: 'itesm-ca2020.firebaseapp.com',
      databaseURL: 'https://itesm-ca2020.firebaseio.com',
      projectId: 'itesm-ca2020',
      storageBucket: 'itesm-ca2020.appspot.com',
      messagingSenderId: '439394464089',
      appId: '1:439394464089:web:70fcf668d6d3c888bd5c48',
      measurementId: 'G-TY0WJ7QE7W'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
